<template>
    <v-container>
      <v-card elevation="0" class="pt-5">
        <div v-if="$store.state.restrictions.loading" class="table__spinnerWrapper">
          <app-spinner class="mt-5 mr-5"></app-spinner>
        </div>
        <div v-else>
          <v-container>
            <v-row class="mr-5 pt-3">
              <v-col md="8" >                
              <h3> {{ $t('hr.employee.check.account') }}: {{ data.account }}</h3>
              </v-col>
              <v-col md="4" >
              <h3>  {{ data.date }}</h3>
              </v-col>
              <v-col md="8">
               <h3> {{ $t('explain') }}: {{ data.desc }}</h3>
              </v-col>
            </v-row>
          </v-container>        
          <v-col md="12" class="mt-8">
            <v-container id="regular-tables" fluid tag="section">
                <v-simple-table :dark="$store.state.isDarkMode" >
                    <thead class="table-heading" v-if="!$store.state.restrictions.loading">
                        <tr>
                            <th v-for="(name, i) in headersResterction" :key="i" >
                                {{ name.text }}
                            </th>
                        </tr>
                    </thead>
                    <div class="table__spinnerWrapper" v-if="$store.state.restrictions.loading">
                        <app-spinner></app-spinner>
                    </div>
                    <v-fade-transition mode="out-in" :group="true" tag="tbody">
                        <tr v-for="(item, idx) in data.student_route_entry" :key="idx"
                            v-show="!$store.state.restrictions.loading">
                            <td>
                               <span>{{ item.entry }}</span>
                            </td>
                            <td>
                                {{ item.date }}
                            </td>
                            <td>
                                {{ item.student }}
                            </td>
                            <td>
                                {{ item.route }}
                            </td>
                            <td>
                                {{ item.amount }}
                            </td>
                            <td>
                              <v-icon color="green" @click="$router.push(`/accountingEntryId/${item.entry}`)">mdi-eye</v-icon>
                            </td>
                        </tr>
                    </v-fade-transition>
                </v-simple-table>
                <div class=" d-flex justify-end mt-5" v-if="!$store.state.restrictions.loading">
                    <app-pagination :totalPages="$store.state.restrictions.serverTotal" :page="$store.state.restrictions.options.page ?? 1"
                        :perPage="10" @PaginationValue="fetchNewPaginated($event)"></app-pagination>
                </div>
            </v-container>
        </v-col>
        </div>
      </v-card>
    </v-container>
  </template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import spinner from "../../../components/base/spinner.vue";
import deleteItem from "../components/core/delete.vue";
import AutoCompleteField from "../components/core/AutoCompleteField.vue";


export default {
  
  components:{
    appSpinner: spinner,
    deleteItem,
    AutoCompleteField,
  },
  data() {
    return {
        
      filters: {
        id: this.$route.params.id,
      },
    };
  },
  mounted() {
    this.fetch(this.filters)
  },
  watch: {
      data() {
        console.log(this.data);
      }
  },
  computed: {
    ...mapGetters({
      data: 'restrictions/data',
      headersResterction: 'restrictions/headersResterction'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'restrictions/fetch',
    }),
    
   
  },
  created(){
   
  },
};
</script>